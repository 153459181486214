
import { defineComponent, ref } from 'vue';
import Logo from '@/components/Logo.vue';
import { format } from 'date-fns';
import AppFieldDate from '@/components/AppFieldDate.vue';
import QForm from 'quasar/src/components/form/QForm.js';;
import axios from 'axios';
import { required as requiredRule } from '@/common/formRules';

export default defineComponent({
  name: 'DownloadFordDefectReport',

  components: {
    AppFieldDate,
    Logo,
  },

  setup() {
    const exporting = ref(false);
    const formRef = ref<QForm | null>(null);
    const model = ref({
      trainNumber: '',
      date: format(new Date(), 'yyyy-MM-dd'),
    });

    const error = ref({
      isError: false,
      message: '',
      status: 0,
    });

    async function searchFetch() {
      const isValid = await formRef.value?.validate();

      error.value.isError = false;
      error.value.message = '';
      error.value.status = 0;

      if (!isValid) return;

      const token = localStorage.getItem('snalltaget:token');

      exporting.value = true;
      await axios({
        url: 'https://pdf-export-service.reges-services.se/ford-train-defect-report/snalltaget',
        data: model.value,
        method: 'POST',
        responseType: 'blob',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (res) => {
          return await res.data;
        })
        .then((blob) => {
          const newBlob = new Blob([blob], {
            type: 'application/pdf',
          });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            const objUrl = window.URL.createObjectURL(newBlob);

            let link = document.createElement('a');
            link.href = objUrl;
            link.download = `snalltaget_kända_skador_${model.value.date}_${model.value.trainNumber}`;
            link.click();

            setTimeout(() => {
              window.URL.revokeObjectURL(objUrl);
            }, 250);
          }

          exporting.value = false;
        })
        .catch((err) => {
          console.dir(err);
          error.value.isError = true;
          if (err.response.status === 401) {
            error.value.message = 'Du har inte tillgång, logga in först!';
            error.value.status = 401;
          } else if (err.response.status === 404) {
            error.value.message = 'Hitta inget på sök kriterierna, försök igen';
            error.value.status === 404;
          } else {
            error.value.message = err.message;
            error.value.status = 0;
          }

          exporting.value = false;
        });
    }

    return {
      error,
      model,
      formRef,
      exporting,
      searchFetch,
      requiredRule,
    };
  },
});
