
import QForm from 'quasar/src/components/form/QForm.js';;
import { defineComponent, ref } from 'vue';
import { required as requiredRule } from '@/common/formRules';
import axios from 'axios';
import Logo from '@/components/Logo.vue';

export default defineComponent({
  components: {
    Logo,
  },

  setup(_, { emit }) {
    const formRef = ref<QForm | null>(null);
    const model = ref({
      username: '',
      password: '',
    });
    const pending = ref(false);
    const error = ref({
      isError: false,
      message: '',
    });

    interface FormData {
      grant_type: string;
      username: string;
      password: string;
      scope: string;
      client_id: string;
      [key: string]: string;
    }

    async function signIn() {
      const isValid = await formRef.value?.validate();
      if (!isValid) return;

      error.value.isError = false;
      error.value.message = '';
      pending.value = true;

      function objToURIComponent(obj: FormData) {
        const str = [];
        for (const p in obj) {
          if (p in obj) {
            str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
          }
        }
        return str.join('&');
      }

      const data = objToURIComponent({
        grant_type: 'password',
        username: model.value.username,
        password: model.value.password,
        scope: 'snalltaget',
        client_id: 'traindApp',
      });

      await axios({
        method: 'post',
        url: 'https://api.traind.se/token',
        data,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
        .then(({ data }) => {
          window.localStorage.setItem('snalltaget:token', data.access_token);
          emit('success', data.access_token);
        })
        .catch((err) => {
          error.value.isError = true;
          error.value.message = err.message;
        });

      pending.value = false;
    }

    return {
      formRef,
      model,
      signIn,
      requiredRule,
      pending,
      error,
    };
  },
});
