import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/logo.svg'


const _hoisted_1 = {
  alt: "Quasar logo",
  src: _imports_0,
  style: {"height":"42px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", _hoisted_1))
}