
import { validDateRule } from '@/common/formRules';
import isMatch from 'date-fns/isMatch/index';
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'AppFieldDate',

  emits: ['update:modelValue'],

  props: {
    square: {
      type: Boolean,
      default: () => true,
    },
    mask: {
      type: String,
      default: () => '####-##-##',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    modelValue: null,
    label: String,
  },

  setup(props, { emit }) {
    const internalModel = ref(props.modelValue);

    watch(
      internalModel,
      (value: string) => {
        if (
          value === props.modelValue ||
          !value ||
          !isMatch(value, 'yyyy-MM-dd')
        )
          return;
        emit('update:modelValue', value);
      },
      {
        immediate: true,
      },
    );

    return { internalModel, validDateRule };
  },
});
