<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container>
      <q-page class="flex flex-center">
        <q-slide-transition>
          <DownloadFordDefectReport
            v-if="token"
            @sign-in="() => (token = null)"
          />
          <SignIn v-else @success="onSignInSuccess" />
        </q-slide-transition>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import SignIn from '@/components/SignIn.vue';
import DownloadFordDefectReport from '@/components/DownloadFordDefectReport';
import { ref, defineComponent } from 'vue';

export default defineComponent({
  name: 'App',

  components: {
    SignIn,
    DownloadFordDefectReport,
  },

  setup() {
    const token = ref(window.localStorage.getItem('snalltaget:token'));

    function onSignInSuccess(value) {
      token.value = value;
    }

    return {
      token,
      onSignInSuccess,
    };
  },
});
</script>
